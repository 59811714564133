<template>
  <strong class="d-block text-truncate">{{ getProducerName() }}</strong>
</template>

<script>
import get from "lodash/get";
export default {
  name: "MarketOrderTableVariantProducer",

  methods: {
    getProducerName() {
      return get(this.data, "ppm.lot.productproducer.producer.role.name");
    },
  },
};
</script>
